<template>
  <v-container class="app-page">
    <viewer-pdf 
      v-if="src"
      :content="src"
      :id="id"
      @loaded="onLoaded"
      @error="onError" 
    ></viewer-pdf>
  </v-container>
</template>

<script>
import ViewerPdf from '@/components/pdf/Viewer'
import { getDetalleCalculo } from '@/api/core'

export default {
  name: "notificaciones-pdf",
  
  components: {
    ViewerPdf
  },

  data() {
    return {
      id: null,
      src: null
    }
  },

  created() {
    this.id = this.$route.params && this.$route.params.id
  },

  watch: {},

  computed: {},

  mounted() {
    this.$store.commit("setNavigationDrawer", false)
    this.$store.commit('setBackButton', true)
    this.$store.commit('startProcessing', { 'value': true, 'message': 'Cargando...'})
    this.fetchPdf()
  },

  methods: {

    async fetchPdf() {
      const data = await getDetalleCalculo(this.id, 1)

      this.src = new Uint8Array(data)
    },

    onLoaded() {
      this.$store.commit('stopProcessing')
    },

    onError() {
      this.$store.commit('stopProcessing')
      this.$store.commit('setSnackBar', {
        'message': 'No se ha podido visualizar el documento. Intenta descargarlo haciendo clic en el icono de desacarga ubicado en la botonera superior',
        'show': true,
        'color': '#cc0605',
        'timeout': 10000
      });
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.pdf-content {
  border: 1px solid #CCC;
}
</style>
